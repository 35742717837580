<template>
    <span v-if="value">
        <i class="fa fa-check"></i>
    </span>
    <span v-else>
        <i class="fa fa-remove"></i>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
        },
    },
}
</script>

<style></style>
